<template>
  <v-app id="app-view">
    <v-app-bar dense app hide-on-scroll color="bg3">
      <v-img alt="Golf.Nanks" class="shrink mr-2 hidden-sm-and-up" src="../assets/golfnanks.svg" transition="scale-transition" width="148"/>

      <v-img alt="Golf.Nanks" class="shrink mr-2 hidden-xs-only" src="../assets/golfnanks.svg" transition="scale-transition" width="232" />
      <span class="caption mt-5 grey--text hidden-xs-only">{{version}}</span>
      <span class="caption mt-3 grey--text hidden-sm-and-up">{{version}}</span>
      <v-spacer></v-spacer>

      <v-btn icon v-if="user" text to="/settings">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
      <v-btn small v-else text to="/login">Log In</v-btn>

      <template v-slot:extension class="py-0">
        <v-btn-toggle group>
          <v-btn min-width="30px" tile text active-class="nav" to="/"><v-icon>mdi-home</v-icon></v-btn>
          <v-btn v-if="currentRounds" min-width="50px" tile text active-class="nav" to="/scorecard"><v-icon>mdi-square-edit-outline</v-icon></v-btn>
          <v-btn v-if="isLive('vegas')" min-width="50px" tile text active-class="nav" to="/leaders/live/vegas"><span class="vegas-font">V</span></v-btn>
          <v-btn v-if="isLive('bfg')" min-width="50px" tile text active-class="nav" to="/leaders/live/bfg"><span class="bfg-font">B</span></v-btn>
          <v-btn v-if="isLive('smss')" min-width="50px" tile text active-class="nav" to="/leaders/live/smss"><span class="smss-font">$</span></v-btn>
          <v-btn v-if="isLive('mbWed')" min-width="50px" tile text active-class="nav" to="/leaders/live/mbWed"><span class="mbwed-font">A</span></v-btn>
        </v-btn-toggle>

        <v-spacer></v-spacer>
        
        <v-btn v-if="user && !currentRounds" small color="accent" to="/newround"><v-icon small class="mr-2">mdi-square-edit-outline</v-icon>New Round</v-btn>
      </template>
      
    </v-app-bar>

    <v-main>

        <v-slide-x-transition leave-absolute>
          <router-view class="page"/>
        </v-slide-x-transition>

    </v-main>
  </v-app>
</template>

<script>
// import moment from 'moment'

  export default {
    data: () => ({
      version: 'v11.4.2',
    }),
    props: {
      source: String
    },
    computed: {
      user() {
        return this.$store.getters.getUser
      },
      currentRounds() {
        return this.$store.getters.getCurrentRounds
      },
      calendar() {
        return this.$store.getters.getCalendar
      },
    },
    methods: {
      isLive(type) {
        return (this.calendar.findIndex(e => e.type == type) >= 0)
      },
    },
  }
</script>
